<script>
import TablePage from '../../../../../../components/table_page';

export default {
  extends: TablePage,
  props: {
    propsObj: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {

      params: {
        enableStatus: '009',
      },
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          // {
          //   name: '确认',
          //   buttonType: '0',
          //   buttonCode: 'submitAssociated',
          // },
        ],
        columns: [],
        tableConfig: {},
      },
    };
  },
  created() {
    this.getConfigList('mdm_position_information_associated_select_position', true, true);
  },
  mounted() {
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },

    buttonClick({ val }) {
      if (val.buttonCode === 'submitAssociated') {
        if (this.selectRow.length > 0) {
          this.$emit('submit', this.selectRow);
        } else {
          this.$message('请勾选');
        }
      }
    },
  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
