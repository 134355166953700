<!--<template>-->
    <!--<div></div>-->
<!--</template>-->

<script>
// import configs from '../data';
// import Form from '../../../../../components/form';
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

import associatedCustomerSelectPosition from './associated_customer_select_position.vue';

export default {
  extends: TablePage,
  components: {
    associatedCustomerSelectPosition,
  },
  props: {
    userItemRowObj: {},

  },
  data() {
    return {
      // requestUrl: '/mdm/mdmPositionRelationCustomerController/findCustomerHasRelateCurPosition',
      // configs,
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '解除关联',
            buttonType: '0',
            buttonCode: 'delAssociated',

          },
          {
            name: '替换对接岗位',
            buttonType: '0',
            buttonCode: 'replacePosition',

          },
        ],
        columns: [],
        tableConfig: {},
      },
    };
  },
  created() {
    console.log(411111, this.userItemRowObj);
    this.params = { ...this.params, positionCode: this.userItemRowObj.positionCode };
    this.getConfigList('mdm_position_information_associated_customer_three', true, true);
  },
  mounted() {
    this.rule = [];
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      // 取消关联
      if (val.buttonCode === 'delAssociated') {
        if (this.selectRow.length > 0) {
          this.$confirm('确认提交吗？', '提示').then(() => {
            request.post('/mdm/mdmPositionRelationCustomerController/unbindRelation', this.selectRow.map((a) => a.id)).then((res) => {
              if (res.success) {
                this.$emit('refresh');
                this.selectRow = [];
              }
              this.$message({
                message: res.message,
                type: 'success',
              });
            });
          });
        } else {
          this.$message('请勾选');
        }
      }

      if (val.buttonCode === 'replacePosition') {
        if (this.selectRow.length > 0) {
          this.formName = 'associatedCustomerSelectPosition';
          this.modalConfig.title = '替换对接岗位';
          this.modalConfig.showFooter = true;
          this.propsObjInData = this.userItemRowObj;
          this.openModal();
        } else {
          this.$message('请勾选');
        }
      }
    },
    onModalOk() {
      if (this.$refs.modalForm.selectRow && this.$refs.modalForm.selectRow.length > 0) {
        this.onSubmit(this.$refs.modalForm.selectRow);
      } else {
        this.$message('请勾选');
      }
    },

    // 确认事件 onSubmit

    onSubmit(positionArr) {
      const params = {
        ids: this.selectRow.map((a) => a.supplyId),
        positionCode: positionArr[0].positionCode,
      };
      request.post('/mdm/mdmPositionRelationCustomerController/replaceCustomerPosition', params).then((res) => {
        if (res.success) {
          this.selectRow = [];
          this.closeModal();
          this.$emit('refresh');
          this.$message({
            message: res.message,
            type: 'success',
          });
        }
      });
    },
  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
