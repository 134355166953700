import request from '../../../../../utils/request';
// import BasePage from '../../../../../components/page_base';
import TablePage from '../../../../../components/table_page';

// 关联客户
import associatedCustomer from '../associated/associated_customer/associated_customer.vue';
// 关联设备
import associatedTerminals from '../associated/associated_terminals/associated_terminals.vue';

// import lookSubordinates from '../associated/look_subordinates/look_subordinates.vue';
import lookSubordinates from '../associated/look_subordinates/look_subordinates_box.vue';

// 新增
import addPosition from '../add_position/add_position.vue';

export default {
  name: 'position_information',
  // extends: BasePage,
  extends: TablePage,
  components: {
    associatedCustomer,
    associatedTerminals,
    lookSubordinates,
    addPosition,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmPositionController/pageList',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('position_list');
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      console.log(val);
      this.params.code = val;
      this.getList();
    },
  },
  methods: {
    // 按钮点击
    modalClick({ val, row }) {
      console.log(299999, val, row);

      if (val.code === 'associated_customers') {
        this.formConfig = row;
        this.formName = 'associatedCustomer';
        this.modalConfig.title = '关联客户';

        this.openFull();
      }
      if (val.code === 'associated_terminal') {
        this.formConfig = row;
        this.formName = 'associatedTerminals';
        this.modalConfig.title = '关联终端';
        this.openFull();
      }
      if (val.code === 'view_subordinates') {
        this.propsObjInData = row;
        this.formName = 'lookSubordinates';
        this.modalConfig.title = '查看下属';
        this.openFull();
      }
      if (val.code === 'add') {
        this.formConfig = { ...val };
        // this.formConfig = row;
        this.formName = 'addPosition';
        this.modalConfig.title = '新增';
        this.modalConfig.width = '1000px';
        this.modalConfig.height = '300px';
        this.openModal();
      }
      if (val.code === 'edit') {
        this.formConfig = { ...row, buttonCode: 'edit' };
        this.formName = 'addPosition';
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '1000px';
        this.modalConfig.height = '300px';
        this.openModal();
      }
      if (val.code === 'view') {
        this.formConfig = { ...row, ...val, buttonCode: 'view' };
        this.formName = 'addPosition';
        this.modalConfig.title = '查看';
        this.modalConfig.width = '1000px';
        this.modalConfig.height = '300px';
        this.openModal();
      }
    },
    /**
     * @override
     * 提交表单
     * @param formData
     */
    onSubmit(formData) {
      request.post('/tpm/tpmfiscalyearsetting/save', formData).then((res) => {
        console.log(res);
      });
    },
    /**
     * @override
     * 可根据字段field 设置列属性
     * @param col
     * @returns {any}
     */
    setColumn(col) {
      const button = col;
      if (button.field === 'positionLevelCode') {
        button.isSearch = true;
        button.paramsName = 'positionLevelName';
        button.apiUrl = '/mdm/mdmPositionLevelController/selectList';
        button.optionsKey = {
          label: 'positionLevelName',
          value: 'positionLevelCode',
        };
      }
      if (button.field === 'orgCode') {
        button.isSearch = true;
        button.paramsName = 'orgName';
        button.apiUrl = '/mdm/mdmOrgController/findOrgSelectList';
        button.optionsKey = {
          label: 'orgName',
          value: 'orgCode',
        };
      }
      return { ...button };
    },
    setRowBtn(btn) {
      // if(btn.code ===){

      // }
      return { ...btn };
    },
    /**
     * @override
     * 可根据字段field 设置列属性
     * @returns {any}
     * @param field
     */
    setFormField(field) {
      if (field.field === 'feeBudgetCode') {
        Object.assign(field, {
          on: {
            change() {},
          },
        });
      }
      return { ...field };
    },
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },
  },
};
