<template>
  <div>
    <lookSubordinates :propsObj="propsObj"></lookSubordinates>
    <div class="dialog-footer">
      <el-button
        type="danger"
        @click="()=>{$emit('onClose')}"
        size="small"
        icon="el-icon-close"
      >关闭
      </el-button>
    </div>
  </div>
</template>

<script>
import lookSubordinates from './look_subordinates.vue';

export default {
  name: 'look_subordinates_box.vue',
  components: {
    lookSubordinates,
  },
  props: {
    propsObj: {},

  },
};
</script>

<style scoped>

</style>
