<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';
import associatedCustomerSelectPosition from './associated_customer_select_position.vue';

export default {
  extends: TablePage,
  props: {
    userItemRowObj: {},

  },
  components: {
    associatedCustomerSelectPosition,
  },
  data() {
    return {
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '添加关联',
            buttonType: '0',
            buttonCode: 'addAssociated',

          },
        ],
        columns: [],
        tableConfig: {},
      },
    };
  },
  created() {
    console.log(411111, this.userItemRowObj);
    if (this.userItemRowObj.enableStatus !== '009') {
      this.configs.toolBarConfig = [];
    }
    this.params = { ...this.params, positionCode: this.userItemRowObj.positionCode };
    this.getConfigList('mdm_position_information_associated_customer_two', true, true);
  },
  mounted() {
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'addAssociated') {
        if (this.selectRow.length > 0) {
          const params = {
            customerCodeList: this.selectRow.map((a) => a.customerCode),
            positionCode: this.userItemRowObj.positionCode,
          };
          request.post('/mdm/mdmPositionRelationCustomerController/bindRelation', params).then((res) => {
            if (res.success) {
              this.selectRow = [];
              this.$emit('refresh');
              this.closeModal();
              this.$message({
                message: res.message,
                type: 'success',
              });
            }
          });
        } else {
          this.$message('请勾选');
        }
      }
    },

  },

};
</script>

<style scoped lang="less">

</style>
