<script>
import { isArray } from 'highcharts';
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  name: 'add_position',
  extends: Form,
  data() {
    return {
      rule: [

      ],
      queryData: {},
    };
  },
  async created() {
    await this.getFormRule('mdm_position_information_add_position');
    // 职位级别
    const positionLevelCode = this.getRule('positionLevelCode');
    positionLevelCode.restful = '/mdm/mdmPositionLevelController/selectList';
    positionLevelCode.headers = { functionCode: 'select-job-level' };
    positionLevelCode.restfulParams = { enableStatus: '009' };
    positionLevelCode.optionsKey = { label: 'positionLevelName', value: 'positionLevelCode' };
    positionLevelCode.props = {
      ...this.props,
      clearable: true,
      filterable: true,
      remote: true,
      remoteParams: 'positionLevelName',
    };
    positionLevelCode.on.change = (e) => {
      request.get('/mdm/mdmPositionLevelController/detail', {
        positionLevelCode: e,
      }).then((res) => {
        if (res.success) {
          if (isArray(res.result.roleList)) {
            this.setValue({
              roleCodeList: [],
            });
          } else {
            const roleCodeArray = isArray(res.result.roleList) && res.result.roleList.map((item) => item.roleCode);
            this.setValue({
              roleCodeList: roleCodeArray,
            });
          }
        }
      });
    };

    // 职位名称
    // const positionName = this.getRule('positionName');
    // positionName.on.change = (e) => {
    //   console.log('职位信息', e);
    // if (e) {
    //   this.disabled(false, ['parentCode']);
    // } else {
    //   this.disabled(true, ['parentCode']);
    //   this.setValue({
    //     positionName: '',
    //   });
    // }
    // };
    // this.disabled(false, ['positionName']);

    // 所属组织
    const orgCode = this.getRule('orgCode');
    orgCode.on.change = (e) => {
      console.log(e);
      if (e) {
        this.disabled(false, ['parentCode']);
      } else {
        this.disabled(true, ['parentCode']);
        this.setValue({
          parentCode: '',
        });
      }
    };
    orgCode.props = {
      ...orgCode.props,
      restful: '/mdm/baseTreeController/orgTree',
      restfulParams: {
        enableStatus: '009',
      },
      headers: { functionCode: 'select-org' },
      props: {
        value: 'code', // 唯一标识
        key: 'code',
        label: 'name', // 标签显示
        children: 'children', // 子级
      },
    };
    // 权限角色
    const roleCodeList = this.getRule('roleCodeList');
    roleCodeList.restful = '/mdm/mdmRoleController/roleSelectList';
    roleCodeList.headers = { functionCode: 'select-permissions-role' };
    roleCodeList.restfulParams = { enableStatus: '009' };
    roleCodeList.optionsKey = { label: 'roleName', value: 'roleCode' };
    roleCodeList.value = [];
    roleCodeList.props = {
      ...roleCodeList.props,
      filterable: true,
      remote: true,
      multiple: true,
      remoteParams: 'roleName',
    };

    // 流程角色
    const bpmRoleCodeList = this.getRule('bpmRoleCodeList');
    bpmRoleCodeList.restful = '/mdm/mdmBpmRoleController/selectList';
    bpmRoleCodeList.headers = { functionCode: 'select-process-role' };
    bpmRoleCodeList.restfulParams = { enableStatus: '009' };
    bpmRoleCodeList.optionsKey = { label: 'bpmRoleName', value: 'bpmRoleCode' };
    bpmRoleCodeList.value = [];
    bpmRoleCodeList.props = {
      ...bpmRoleCodeList.props,
      filterable: true,
      remote: true,
      clearable: true,
      multiple: true,
      remoteParams: 'bpmRoleName',
    };

    // 上级职位
    const parentCode = this.getRule('parentCode');
    parentCode.restful = '/mdm/mdmPositionController/positionSelectList';
    parentCode.headers = { functionCode: 'select-position-up' };
    parentCode.restfulParams = { enableStatus: '009' };
    parentCode.restfulParamsGetValue = {
      notUnderThisOrgCodeExcludeSelf: 'orgCode',
    };
    parentCode.optionsKey = { label: 'unionName', value: 'positionCode' };
    parentCode.props = {
      ...parentCode.props,
      clearable: true,
      filterable: true,
      remote: true,
      remoteParams: 'unionName',
    };

    // 判断是否查看
    if (this.formConfig.buttonCode === 'view') {
      this.rule.push({
        type: 'input',
        title: '创建人',
        field: 'createName',
        value: this.queryData.createName,
        col: {
          md: {
            span: 12,
          },
        },
      });
      this.rule.push({
        type: 'input',
        title: '创建时间',
        field: 'createDateAll',
        value: this.queryData.createDateAll,
        col: {
          md: {
            span: 12,
          },
        },
      });
    }
    this.reload(this.rule);

    this.getPositionDetail(this.formConfig.positionCode);
  },

  methods: {

    // 获取详情  /mdm/mdmPositionController/detail

    getPositionDetail(positionCode = '') {
      if (!positionCode) return false;
      request.get('/mdm/mdmPositionController/detail', { positionCode }).then((res) => {
        if (res.success) {
          const { result } = res;
          const parentCode = this.getRule('parentCode');
          parentCode.restfulParams = {
            ...parentCode.restfulParams,
            excludeChildOrgOfThisPositionCode: result.orgCode,
          };
          this.setValue({
            ...result,
            bpmRoleCodeList: (result.bpmRoleList || []).map((a) => a.bpmRoleCode),
            roleCodeList: (result.roleList || []).map((a) => a.roleCode),
          });
          this.queryData = result;
        }
      });
    },

    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData.roleCodeList === false) {
        formData.roleCodeList = [];
      }
      if (formData) {
        let url = '/mdm/mdmPositionController/save';
        let params = formData;
        if (this.formConfig && this.formConfig.buttonCode === 'edit') {
          url = '/mdm/mdmPositionController/update';
          params = { ...this.queryData, ...formData };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
    },
  },

};
</script>
