<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

import associatedCustomerSelectPosition from './look_subordinates_select_superior.vue';
// import associatedCustomerSelectPosition from '../associated_customer/associated_customer_select_position.vue';

export default {
  extends: TablePage,
  props: {
    propsObj: {},

  },
  components: {
    associatedCustomerSelectPosition,
  },

  data() {
    return {
      isCalculateHeight: false,
      // requestUrl: '/mdm/mdmUserController/pageList',
      params: {
        positionCode: this.propsObj.positionCode,
      },
      formData: {
        underlingPositionLevelCode: '',
        underlingOrgName: '',
        underlingPositionCode: '',
      },
      // formConfig: {},
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [
            // {
            //   field: 'underlingPositionLevelCode',
            //   search: 'true',
            //   title: '下属职位级别',
            //   type: 'select',
            //   apiUrl: '/mdm/mdmPositionLevelController/selectList',
            //   optionsKey: {
            //     label: 'positionLevelName',
            //     value: 'positionLevelCode',
            //   },
            //   isSearch: true,
            //   paramsName: 'positionLevelName',
            //   props: {
            //     remote: true,
            //     filterable: true,
            //   },
            //   paramsFixed: {
            //     enableStatus: '009',
            //   },
            // },
          ],
        },
        toolBarConfig: [
          {
            name: '选择上级',
            buttonType: '0',
            buttonCode: 'addAssociated',
          },
        ],
        columns: [],
        tableConfig: {
          border: true,
          // treeConfig: {
          //   children: 'children',
          // },
          idKey: 'functionCode', // 默认勾选判断字段
          resizable: true, // 所有的列是否允许拖动列宽调整大小
          showOverflow: true, // 当内容过长时显示为省略号
          keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
          highlightHoverRow: true, // 鼠标滑动高亮
          // height: '600',
        },

      },
    };
  },
  created() {
    this.getConfigList('mdm_position_information_associated_look_subordinates', true, true);
  },
  mounted() {

  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'addAssociated') {
        if (this.selectRow.length > 0) {
          this.formName = 'associatedCustomerSelectPosition';
          this.modalConfig = {
            ...this.modalConfig,
            showFooter: true,
            title: '选择上级',
            buttons: {
              sure: true,
              close: true,
            },
          };

          this.propsObjInData = {
            selectRow: this.selectRow,
          };
          this.openModal();
        } else {
          this.$message('请勾选');
        }
      }
    },
    onModalOk() {
      console.log(this.$refs.modalForm);
      if (this.$refs.modalForm.selectRow.length > 0) {
        this.onSubmit(this.$refs.modalForm.selectRow);
        this.closeModal();
      } else {
        this.$message('请勾选');
      }
    },

    onSubmit(positionArr) {
      console.log(733333, positionArr);
      // 下属-关联上级职位 传（underlingPositionCodeList、positionCode）
      /// mdm/mdmUserUnderlingController/replaceParentPosition
      const params = {
        underlingPositionCodeList: this.selectRow.map((a) => a.underlingPositionCode),
        positionCode: positionArr[0].positionCode,
      };
      request.post('/mdm/mdmPositionUnderlingController/replaceParentPosition', params).then((res) => {
        if (res.success) {
          this.selectRow = [];
          this.closeModal();
          this.getList();
          this.$message({
            message: res.message,
            type: 'success',
          });
        }
      });
    },
  },

};
</script>

<style scoped lang="less">

</style>
