<!--<template>-->
    <!--<div></div>-->
<!--</template>-->

<script>
// import configs from '../data';
// import Form from '../../../../../components/form';
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

import associatedCustomerSelectPosition from '../associated_customer/associated_customer_select_position.vue';

export default {
  extends: TablePage,
  props: {
    userItemRowObj: {},

  },
  components: {
    associatedCustomerSelectPosition,
  },
  data() {
    return {
      // requestUrl: '/mdm/mdmUserController/pageList',
      // configs,
      params: {
        positionCode: this.userItemRowObj.positionCode,
      },
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '添加关联',
            buttonType: '0',
            buttonCode: 'addAssociated',

          },
        ],
        columns: [],
        tableConfig: {},
      },
    };
  },
  created() {
    if (this.userItemRowObj.enableStatus !== '009') {
      this.configs.toolBarConfig = [];
    }
    this.getConfigList('mdm_position_information_associated_terminals_two', true, true);
  },
  mounted() {
    this.rule = [];
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'addAssociated') {
        if (this.selectRow.length > 0) {
          const params = {
            terminalCodeList: this.selectRow.map((a) => a.terminalCode),
            positionCode: this.userItemRowObj.positionCode,
          };
          request.post('/mdm/mdmPositionRelationTerminalController/bindRelation', params).then((res) => {
            if (res.success) {
              this.selectRow = [];
              this.$emit('refresh');
              this.closeModal();
              this.$message({
                message: res.message,
                type: 'success',
              });
            }
          });
        } else {
          this.$message('请勾选');
        }
      }
    },

  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
